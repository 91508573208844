import { isArray } from 'lodash'
// import { FormError } from '@sm/client/types'

// flattens a nested dictionary of errors to an array
// TODO type react-hook-forms DeepMap error dictionary?
export const flattenErrors = (errors, prefix = ''): any[] => {
	const result = Object.entries<any>(errors).reduce<any>(
		(acc, [name, value]) => {

			if (!value) {
				return acc
			}

			if (isArray(value)) {
				// we found an error with more errors
				value.forEach((value, index) => {
					if (value) {
						const subResults = flattenErrors(
							value,
							`${prefix}${name}.${index}.`,
						)
						acc = [...acc, ...subResults]
					}
				})
			} else if ('message' in value) { // value.message == true leads to endless loop if property is empty
				// we found a leaf/error/exit criteria
				acc.push({
					...value,
					fieldName: `${prefix}${name}`,
				})
			} else {
				// we found a dictionary with more errors
				const subResults = flattenErrors(value, `${prefix}${name}.`)
				acc = [...acc, ...subResults]
			}

			return acc
		},
		[],
	)

	// console.debug('flattenErrors', errors, result)

	return result
}
