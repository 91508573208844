import React, {
	forwardRef,
	useCallback,
	useState,
	Ref,
} from 'react'
import clsx from 'clsx'
import { Spinner } from '#hh/client/components'
import { PropertySource } from '@neptun/commons'

// import { InputProps, SelectItem } from '#hh/client/types'

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	// name: string
	// placeholder: string
	// className: string
	// errors: any[]
	// dirty: boolean
	// copyable: boolean
	// value: any
	// value: any
	label?: string
	icon?: string
	loading?: boolean
	onChange?: (newValue: string, evt: React.ChangeEvent<HTMLInputElement>) => void
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{
			name,
			// errors,
			// dirty,
			// copyable,
			className,
			loading,
			value = '',
			label,
			icon,
			children,
			onChange,
			...otherProps
		},
		ref,
	) => {

		const handleChange = useCallback(
			(evt) => {
				if (onChange) {
					onChange(evt.currentTarget.value, evt)
				}
			},
			[onChange, value],
		)

		return (
			<div className={clsx('textInput', className, {
				'icon': icon && !loading,
				[`icon:${icon}`]: icon && !loading,
			})}>
				{loading && (
					<Spinner size="small" />
				)}
				<input
					{...otherProps}
					name={name}
					ref={ref}
					value={value || ''}
					className={clsx('input', 'input--text')}
					onChange={handleChange}
				/>
				{children}
			</div>
		)
	},
)

TextInput.displayName = 'TextInput'
