import { useMemo } from 'react'
import { Request } from 'express'
import router from 'next/router'
// import WebSocket from 'ws'
import merge from 'deepmerge'
import { isEqual } from 'lodash'
import { GraphQLError } from 'graphql'
import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	split,
	from as combineLinks,
	NormalizedCacheObject,
	Reference,
	FieldPolicy,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
// import { WebSocketLink } from '@apollo/client/link/ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { onError } from '@apollo/client/link/error'
import { createClient } from 'graphql-ws'

import { getConfig } from '#hh/client/lib'
import { ListResponseType } from '#hh/server/graphql/types'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

const unauthorizedMessage = 'Access denied. Authentication required'

const filterGraphQLError = (error: GraphQLError) => {
	if (
		error.path &&
		error.path[0] === 'viewer' &&
		error.message === unauthorizedMessage
	) {
		return false
	}
	return true
	// return !(error.path[0] === 'viewer' && error.message === 'Access denied. Authentication required')
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		const errors = graphQLErrors.filter(filterGraphQLError)
		errors.forEach(({ message, locations, path }) => {
			console.error(
				`[hh/apollo] Error: ${message}, Location: ${locations}, Path: ${path}`,
			)
			if (message === unauthorizedMessage) {
				router.push('/')
			}
		})
	}
	if (networkError) {
		console.error(`[hh/apollo] Network Error: ${networkError}`)
	}
})

type KeyArgs = FieldPolicy<any>['keyArgs']

function cursorPagination(
	keyArgs: KeyArgs = false,
): FieldPolicy<ListResponseType<Reference>> {
	return {
		keyArgs,
		merge: (existing, incoming, { args, readField }) => {
			// console.debug('merge', args, existing, incoming)

			const existingItems = existing?.items || []
			const existingItemRefs = existingItems.map((item) => item['__ref'])

			const incomingItems = (incoming?.items as Reference[]) || []
			const newItems = incomingItems.filter(
				(item) => !existingItemRefs.includes(item['__ref']),
			)

			let sortedItems
			if (args.filter?.bookmarkList) {
				sortedItems = [...newItems, ...existingItems].sort((a, b) =>
					(readField('date', b) as string).localeCompare(readField('date', a)),
				)
			}

			if (args?.pagination?.beforeCursor) {
				return {
					...incoming,
					...existing,
					items: sortedItems ? sortedItems : [...newItems, ...existingItems],
				}
			} else {
				return {
					...existing,
					...incoming,
					items: sortedItems ? sortedItems : [...existingItems, ...newItems],
				}
			}
		},
	}
}

export function createApolloClient(req?: Request) {
	const config = getConfig()
	const headers: any = {}
	const isSSR = typeof window === 'undefined'

	if (isSSR && req) {
		headers.cookie = req.headers.cookie
	}

	const httpLink = new HttpLink({
		uri: `${config.publicUrl}/api/graphql`,
		credentials: 'same-origin',
		headers,
	})

	const parsedUrl = new URL(config.publicUrl)

	const isSSL = config.publicUrl.startsWith('https')

	// const wsLink = new WebSocketLink({
	// 	uri: (isSSL ? 'wss' : 'ws') + `://localhost:5000/`,
	// 	options: {
	// 		reconnect: true
	// 	}
	// })
	const websocketConfig: any = {
		url: (isSSL ? 'wss' : 'ws') + `://${parsedUrl.host}/api/graphql`,
		// webSocketImpl: WebSocket ? WebSocket : undefined,
	}

	if (isSSR) {
		websocketConfig.webSocketImpl = require('ws')
	}

	const link = new GraphQLWsLink(
		createClient(websocketConfig),
	)

	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query)
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			)
		},
		link,
		httpLink,
	)

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					documents: cursorPagination(['filter']),
					bookmarks: cursorPagination(['filter']),
				},
			},
		},
	})

	if (!isSSR && window[APOLLO_STATE_PROP_NAME]) {
		cache.restore(JSON.parse(window[APOLLO_STATE_PROP_NAME]))
	}

	return new ApolloClient({
		ssrMode: isSSR,
		link: combineLinks([errorLink, splitLink]),
		cache,
		ssrForceFetchDelay: 100, // in milliseconds
	})
}

export function initializeApollo(initialState = null, req?: Request) {
	const _apolloClient = apolloClient ?? createApolloClient(req)

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract()

		// Merge the initialState from getStaticProps/getServerSideProps in the existing cache
		const data = merge(existingCache, initialState, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter((d) =>
					sourceArray.every((s) => !isEqual(d, s)),
				),
			],
		})

		// Restore the cache with the merged data
		_apolloClient.cache.restore(data)
	}
	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') return _apolloClient
	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient

	return _apolloClient
}

export function addApolloState(client, pageProps) {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
	}

	return pageProps
}

export function useApollo(pageProps) {
	const state = pageProps[APOLLO_STATE_PROP_NAME]
	const store = useMemo(() => initializeApollo(state), [state])
	return store
}
