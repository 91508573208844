declare global {
	interface Window {
		_mtm: any[]
	}
}

export function pushTrackingEvent(data: any) {
	console.debug('[hh/client/tracking] push event', data)
	window._mtm = window._mtm || []
	window._mtm?.push(data)
}

export function trackEvent(category: string, name?: string, value?: string) {
	pushTrackingEvent({
		event: 'genericEvent',
		eventCategory: category,
		eventName: name,
		eventValue: value,
	})
}
