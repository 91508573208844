import getNextConfig from 'next/config'

interface ClientConfig {
	publicUrl: string
	systemType: 'STAGING'|'PRODUCTION'
	version: string
	env: 'production' | 'development' | 'test'
	debug: boolean
}

export const getConfig = (): ClientConfig => {
	const nextConfig = getNextConfig()

	const config = {
		...(nextConfig?.publicRuntimeConfig ?? {}),
		...(nextConfig?.serverRuntimeConfig ?? {}),
	}

	return config
}
