import Router from 'next/router'

export function replaceRoute(
	path: string,
	opts: object = {},
): Promise<boolean> {

	const options = {
		shallow: true,
		...opts,
	}

	return Router.replace(path, path, options)

}

export function pushRoute(
	path: string,
	opts: object,
): Promise<boolean> {

	const options = {
		shallow: true,
		...opts,
	}

	return Router.push(path, path, options)

}
