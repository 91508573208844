import React, { Ref, FunctionComponent } from 'react'
import { useTranslations } from '#hh/client/hooks'

export interface Error {
	type: string
	message?: string
	field?: string
	row?: number
}

export const FormErrorList: FunctionComponent<any> = ({
	items,
	className,
}) => {
	const { messages } = useTranslations()
	if (!items || !items.length) {
		return null
	}
	


	return (
		<ul className={'form__errors'}>
			{items.map((error, index) => {
				const message = messages[error.message] //error.message || messages[error.type]
				return (
					<li key={index}>
						{/* {error.message} */}
						{message}
					</li>
				)

			})}
		</ul>
	)

}

