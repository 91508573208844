import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
	useForm as useHookForm,
	useFormContext,
	UseFormReturn as UseHookFormReturn,
} from 'react-hook-form'
import { AnyObjectSchema } from 'yup'
import isDate from 'date-fns/isDate'
import { isObject, isArray, get, pickBy } from 'lodash'
import flat from 'flat'
import { DocumentNode } from 'graphql/index.mjs'
import { useQuery, useMutation } from '@apollo/client'
import { gql, ApolloError, FetchResult, MutationResult } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFieldErrors, flattenErrors } from '#hh/client/lib'
import { FormError } from '#hh/client/types'
export interface UseFormOptions<TData, TValues, TSubmission> {
	idProperty?: string
	schema: AnyObjectSchema
	query?: DocumentNode
	mutation: DocumentNode
	lock?: any
	variables?: Partial<TSubmission>
	castValues?: boolean
	// createQueryData?: (dataSources: any) => TData
	createFormValues?: (data: TData) => TValues
	transformValues?: (values: TValues) => TValues
	createSubmissionVariables?: (values: TValues) => Partial<TSubmission>
	queryDataPath?: string
	mutationErrorsPath?: string
	mutationDataPath?: string

	defaultValues?: any // TData // Todo fix typing, see EditEvent useEventForm call
	onSubmissionResponse?: Function
	onError?: (error: ApolloError) => void
}

interface SubmissionResult {
	data: any
	errors: any
}

export interface UseFormReturn<TData, TValues, TSubmission>
	extends UseHookFormReturn {
	values: TValues
	data: TData
	variables: any
	errors: FormError[]
	dirtyCount: number
	errorCount: number
	isDisabled: boolean
	isLoading: boolean
	isSubmitting: boolean
	isSubmitSuccessful: boolean
	submit: (variables?: Partial<TSubmission>) => Promise<SubmissionResult>
	validate: Function
	reload: (variables?: any) => Promise<void>
}

const NoOpQuery = gql`
	query {
		viewer
	}
`

// filters form data from hidden keys or invalid values

const filterSubmissionValues = (values): any => {
	const isHidden = (key, value) => {
		return key.startsWith('_') || typeof value === 'undefined'
	}

	const result = {}

	for (const [key, value] of Object.entries(values)) {
		// console.debug(key, value)

		if (isHidden(key, value)) {
			continue
		}

		if (isArray(value)) {
			result[key] = value.map((item) => {
				if (isObject(item)) {
					if (!isDate(item) && !(item instanceof File)) {
						return filterSubmissionValues(item)
					}
				}
				return item
			})
		} else if (isObject(value) && !(value instanceof File) && !isDate(value)) {
			result[key] = filterSubmissionValues(value)
		} else {
			result[key] = value
		}
	}

	return result
}

const isDOMEvent = (e: object): e is React.SyntheticEvent => {
	return (e as React.SyntheticEvent).defaultPrevented !== undefined
}

export const useForm = <TData, TValues = Partial<TData>, TSubmission = any>(
	options: UseFormOptions<TData, TValues, TSubmission>,
): UseFormReturn<TData, TValues, TSubmission> => {
	// const idProperty = options?.idProperty ?? 'id'
	const hasQuery = !!options?.query
	const queryDataPath = options.queryDataPath ?? 'queryResult'
	const mutationDataPath = options.mutationDataPath ?? 'mutationResult.data'
	const mutationErrorsPath =
		options.mutationErrorsPath ?? 'mutationResult.errors'

	const [formData, setFormData] = useState<Partial<TData>>({})
	const [formValues, setFormValues] = useState<Partial<TValues>>(options.defaultValues)
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isDisabled, setDisabled] = useState<boolean>(hasQuery)
	const [isSubmitSuccessful, setSubmitSuccessful] = useState<boolean>(false)
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [formErrors, setFormErrors] = useState<FormError[]>([])

	// !lock.acquired && !form.isLoading

	// const toast = useToast()
	// const modal = useModal()
	// const router = useRouter()

	const form = useHookForm<any>({
		// mode: 'onBlur',
		resolver: yupResolver(options.schema),
		defaultValues: formValues,
		// shouldUnregister: true,
		shouldUnregister: false,
	})

	useEffect(() => {
		if (options.defaultValues && !hasQuery) {
			const defaultData = options.defaultValues
			const defaultFormValues = options.createFormValues
				? options.createFormValues(defaultData)
				: defaultData

			setFormData(defaultData)
			form.reset(defaultFormValues)
			setFormValues(defaultFormValues)
		}
	}, [])

	const dirtyCount = useMemo(() => {
		const dirtyFields = flat(form.formState.dirtyFields)
		// console.debug('recalc dirty', dirtyFields)

		// TODO Exclude fields beginning with _ in general?
		const skipKeys = ['__typename', '_rowKey']
		const dirtyKeys = Object.keys(dirtyFields).filter((key) => {
			const value = dirtyFields[key]
			const keyEnding = key.split('.')?.slice(-1)?.[0]

			if (!value || (isArray(value) && !value.length)) {
				return
			} else if (
				skipKeys.includes(key) ||
				(keyEnding && skipKeys.includes(keyEnding))
			) {
				return
			}
			return true
		})
		return dirtyKeys.length

		// return Object.values(dirtyFields).filter(
		// 	(v) => v && (!isArray(v) || v.length),
		// ).length
	}, [form.formState])

	// const handleMutationError = useCallback(
	// 	(error: ApolloError) => {
	// 		// console.debug('[form] handleMutationError', error, foo)
	// 		if (options.onError) {
	// 			options.onError(error)
	// 		}

	// 		// modal.error({
	// 		// 	title: 'Server Error',
	// 		// 	subtitle: 'Fehler sind aufgetreten während des Absendeprozesses.',
	// 		// 	error,
	// 		// })

	// 		// toast.error(error.toString())
	// 	},
	// 	[options.onError],
	// )

	const [sendSubmission] = useMutation(options.mutation)

	const handleQueryError = useCallback(
		(error: ApolloError) => {
			// console.debug('[form] handleQueryError', error, error.graphQLErrors, query)
			if (options.onError) {
				options.onError(error)
			}

			// modal.error({
			// 	title: 'Loading Error',
			// 	subtitle: 'Fehler sind aufgetreten während des Ladeprozesses.',
			// 	error,
			// 	context: {
			// 		variables: options.variables,
			// 	},
			// })

			setDisabled(true)
		},
		[options.onError, options.variables],
	)

	const query = useQuery(options?.query ?? NoOpQuery, {
		variables: options.variables,
		skip: !hasQuery,
		// https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
		fetchPolicy: 'network-only', // to disable cache
		onError: handleQueryError,
		onCompleted: (queryData) => {
			const data = get(queryData ?? query?.data, queryDataPath)

			// console.debug('[form] onCompleted', data)

			const loadedFormValues = options.createFormValues
				? options.createFormValues(data)
				: data

			// console.debug('[form] onCompleted setFormValues', loadedFormValues)
			setFormData(data)

			const newValues = loadedFormValues

			if (dirtyCount) {
				// only set values from response if form has no changes
				const existingValues = form.getValues()
				const dirtyValues = pickBy(existingValues, (v) => v !== undefined)
				const newValues = {
					...loadedFormValues,
					...dirtyValues,
				}
				// console.debug('[form] update dirty form', newValues, dirtyValues)
				// console.debug('new values', changedValues, newValues)
				form.reset(newValues, {
					keepValues: true,
					keepTouched: true,
					keepDirty: true,
				})
			} else {
				form.reset(newValues, {
					// keepDefaultValues: false,
				})
			}

			setFormValues(newValues)
			setDisabled(false)
		},
	})

	const clearErrors = useCallback(() => {
		form.clearErrors()
		setFormErrors([])
	}, [form.clearErrors])

	const validate = useCallback(() => {
		setFormErrors([])
		return form.trigger()
	}, [form.trigger])

	const reload = useCallback(
		async (variables = {}) => {
			// const { createFormValues } = options
			const res = await query.refetch({
				...options.variables,
				...variables,
			})

			// console.debug('refetch', res)
			const data = get(res.data, queryDataPath)

			const newValues = options.createFormValues
				? options.createFormValues(data)
				: data
			// console.debug('[form] reload created new values', newValues)
			setFormData(data)
			setFormValues(newValues)
			form.reset(newValues)
		},
		[options.variables, query.refetch, options.createFormValues],
	)

	const submit = useCallback(
		async (submitVariables: Partial<TSubmission> = {}) => {
			const {
				// getQueryData,
				createFormValues,
				createSubmissionVariables,
				transformValues,
				onSubmissionResponse,
			} = options

			// gather data & validate
			const rawValues = form.getValues()
			const isValid = await validate()

			console.debug('[form] submission', isValid, rawValues)

			if (!isValid) {
				// console.debug(
				// 	'[form] invalid submission',
				// 	form.formState.errors,
				// 	rawValues,
				// )
				return {
					data: null,
					errors: form.formState.errors,
				}
			}

			// set loading state

			setDisabled(true)
			setLoading(true)
			setSubmitting(true)

			// pre submission data processing

			let values = rawValues

			// let submissionValues = filterSubmissionValues(rawValues)
			// console.debug('[form] after filter', submissionValues)

			if (transformValues) {
				values = transformValues(values)
				// console.debug('[form] transformedValues', values)
			}

			values = filterSubmissionValues(values)
			// console.debug('[form] after filter', values)

			if (options.schema) {
				const castValues = options.schema.cast(values)
				// console.debug('[form] cast values', values, castValues)

				if (castValues) {
					values = castValues
				}
			}

			const submissionVariables: Partial<TSubmission> =
				createSubmissionVariables ? createSubmissionVariables(values) : values

			let extraVariables: Partial<TSubmission> = {}

			if (submitVariables && !isDOMEvent(submitVariables)) {
				extraVariables = submitVariables
			}

			const variables = {
				...options.variables,
				...submissionVariables,
				...extraVariables,
			} as TSubmission

			// console.debug('[form] final submission', variables)

			// submit final data

			let res: FetchResult<any> = { data: null }

			try {
				res = await sendSubmission({ variables })
			} catch (err) {
				console.error(err)
				// toast.error(err.toString())
				// modal.error({
				// 	title: 'Submission Error',
				// 	subtitle: 'Fehler sind aufgetreten während des Verarbeitungsprozesses.',
				// 	error: err,
				// 	context: {
				// 		variables,
				// 	},
				// })
			}

			// console.debug('res', res)

			if (res.data) {
				console.debug('[form] submission response', res)

				const data = get(res.data, mutationDataPath)
				const errors = get(res.data, mutationErrorsPath)

				// console.debug('[form] mutation data', mutationDataPath, data)
				if (errors) {
					// console.debug('[form] mutation errors', mutationErrorsPath, errors)
				}

				if (onSubmissionResponse) {
					await onSubmissionResponse({
						data,
						errors,
						raw: res.data,
					})
				}

				if (errors?.length) {
					const newFormErrors: FormError[] = []

					errors.forEach(({ field, message }) => {
						if (field) {
							// console.debug('setError', field, message)
							form.setError(field, {
								type: 'server',
								message,
							})
						} else if (message) {
							newFormErrors.push({
								type: 'server',
								message,
							})
							// console.debug('setError', 'global', message)
						}
						setFormErrors(newFormErrors)
					})
				}

				if (data) {
					const newValues = createFormValues ? createFormValues(data) : data
					console.debug('[form] post-submission setFormValues', newValues)
					setFormData(data)
					setFormValues(newValues)
					form.reset(newValues, {
						keepDirtyValues: false,
					})
				}

				if (!errors?.length) {
					setSubmitSuccessful(true)
				}

				// window.requestIdleCallback(() => {
				setLoading(false)
				setSubmitting(false)
				setDisabled(false)
				
				// })

				return {
					data,
					errors,
				}
			}

			// window.requestIdleCallback(() => {
			setLoading(false)
			setSubmitting(false)
			setDisabled(false)
			// })

			return {
				data: null,
				errors,
			}
		},
		[form, formValues, options, validate],
	)

	// flatten form errors

	const errors = useMemo(() => {
		const fieldErrors = flattenErrors(form.formState.errors)
		return [...formErrors, ...fieldErrors]
	}, [formErrors, form.formState.errors])

	return {
		...form,
		variables: options.variables,
		data: formData as TData,
		values: formValues as TValues,
		errors,
		dirtyCount,
		errorCount: errors.length,
		// isLoading: typeof window === 'undefined' || isLoading || query.loading,
		isLoading: isLoading || query.loading,
		isDisabled,
		isSubmitting,
		isSubmitSuccessful,
		submit,
		reload,
		validate,
		clearErrors,
		// setFormError: (message: string) => {
		// 	form.setError('_global', {
		// 		type: 'manual',
		// 		message,
		// 	})
		// },
		// setFieldError: (name: string, message: string) => {
		// 	form.setError(name, {
		// 		type: 'manual',
		// 		message,
		// 	})
		// },
	}
}

interface FormFieldOptions {
	onChange?: Function
	defaultValue?: any
}

interface SetValueConfig {
	shouldValidate: boolean
	shouldDirty: boolean
}

// interface FormFieldReturn extends UseHookFormReturn {
// 	// interface FormField {
// 	// form: UseFormMethods,
// 	dirty: boolean
// 	set: Function
// 	value?: any
// 	errors: FieldErrors<any>
// }

export interface FormField {
	errors: FormError[]
	dirty: boolean
	value?: any
	setValue: (newValue: any, config: SetValueConfig) => void
}

export const useFormField = (
	name: string,
	options: FormFieldOptions = {},
): FormField => {
	const form = useFormContext()
	const value = form?.watch(name, options.defaultValue)

	// const value = useWatch({
	// 	name,
	// 	defaultValue: options.defaultValue,
	// })

	const errors = useMemo(() => {
		return getFieldErrors(name, form?.formState?.errors)
	}, [name, form?.formState])

	const dirty = useMemo(() => {
		if (form?.formState) {
			return get(form.formState.dirtyFields, name)
		}
		return false
	}, [name, form?.formState, value])

	// const set = useCallback(
	// 	(newValue, options?: SetValueConfig) => {
	// 		if (!name.startsWith('_')) {
	// 			console.debug('form set', name, newValue, options)
	// 			form.setValue(name, newValue, options)
	// 		}
	// 	},
	// 	[name, form.setValue],
	// )
	const setValue = useCallback(
		(newValue, config) => {
			if (form?.setValue) {
				return form.setValue(name, newValue, config)
			}
		},
		[name, form?.setValue],
	)

	// console.debug('useFormField', name, value)

	return {
		// form: methods,
		// ...form,
		value,
		setValue,
		// set,
		dirty,
		errors,
	}
}
