import React, { ReactNode } from 'react'
import { NextPage } from 'next'
import { useApollo, initializeApollo, APOLLO_STATE_PROP_NAME } from '#hh/client/hooks'
import { ApolloProvider } from '@apollo/client'

export const withApollo =
	() =>
	// (PageComponent: NextPage): ReactNode => {
	(PageComponent: any): ReactNode => {
		const WithApollo = (pageProps) => {

			const client = useApollo(pageProps)

			return (
				<ApolloProvider client={client}>
					<PageComponent {...pageProps} />
				</ApolloProvider>
			)
		}

		WithApollo.getInitialProps = async (ctx) => {
			// console.debug('WithApollo getInitialProps', Object.keys(ctx.req))
			const apolloClient = initializeApollo(null, ctx.req)

			const newContext = {
				...ctx,
				apolloClient,
			}
			// const apolloData = apolloClient.cache.extract()
			// console.debug('apollodata', apolloData)

			return {
				...(PageComponent.getInitialProps &&
					(await PageComponent.getInitialProps(newContext))),
				[APOLLO_STATE_PROP_NAME]: apolloClient.cache.extract(),
			}
		}

		WithApollo.getLayout = PageComponent.getLayout

		return WithApollo as any
	}
