import React from 'react'
import Head from 'next/head'
import clsx from 'clsx'

interface ViewProps {
	title?: string
	children?: React.ReactNode
	className?: string
}

export function View({ title, children, className }: ViewProps) {
	return (
		<div
			// ref={containerRef}
			className={clsx('view', className, {
				// 'sidebar:open': hasMenu && isMenuOpen
			})}
		>
			<Head>
				<title>{`${title} - HeadlineHunter`}</title>
			</Head>
			{children}
		</div>
	)
}
