export * from './routing'
export * from './lang'
// export * from './date'
// export * from './getObjectPathValue'
// export * from './normalizeDateRangeFilter'
// export * from './intervalToDuration'

export const isServerSide = () => {
	return typeof window === 'undefined'
}

export const isClientSide = () => {
	return typeof window !== 'undefined'
}

export function isTouchDevice() {
	return (
		(typeof window !== 'undefined' && 'ontouchstart' in window) ||
		(typeof navigator !== 'undefined' &&
			(navigator?.maxTouchPoints > 0 ||
				// @ts-ignore
				navigator?.msMaxTouchPoints > 0))
	)
}
