import React, { useMemo } from 'react'
import clsx from 'clsx'

interface SpinnerProps {
	size?: "small" | "large"
	inline?: true
	inverted?: true
	style?: React.CSSProperties
	className?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ className, size, inline, inverted, style }) => {

	const tagName = inline ? 'span' : 'div'

	return React.createElement(tagName, {
		style,
		className: clsx('spinner', className, {
			[`spinner--${size}`]: size,
			'spinner--inline': inline,
			'spinner--inverted': inverted,
		})
	})
}