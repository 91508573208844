import { UserData } from '@neptun/commons'
import { pushTrackingEvent } from '#hh/client/lib'

interface PageViewContext {
	user?: UserData
	query?: any
}

export function pushPageViewEvent(context?: PageViewContext) {
	console.debug('[hh/client/tracking] page view', context)
	pushTrackingEvent({ 'UserID': context?.user?.email })
	pushTrackingEvent({ event: 'CustomPageView' })
}
