import { flowRight } from 'lodash'

import { withApollo, withIntl, withAuth, PageAuthConfig } from './'

interface PageConfig extends PageAuthConfig {
	ssr?: boolean
}

export const composePage =
	(config: PageConfig = {}) =>
	(PageComponent) =>
		flowRight(
			withApollo(),
			withAuth({
				// capability: capability,
				// isPublic: public && !capability,
				public: config.public,
			}),
			withIntl(),
			// withReferrer(),
		)(PageComponent)
