import React, { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import clsx from 'clsx'
// import { setLocale } from 'yup'
import { UseFormReturn} from '#hh/client/hooks'
import { FormErrorList } from '#hh/client/components'

// export interface FormProps extends UseFormMethods {
// 	id?: string
// 	submit: any
// 	data: any
// 	className?: string
// 	children?: any
// }

export interface FormProps extends UseFormReturn<any, any, any> {
	id?: string
	submit: any
	// data?: any
	className?: string
	children?: any
	autoComplete?: string
}

export const Form: React.FC<FormProps> = ({
	id,
	children,
	submit,
	className,
	autoComplete,
	...form
}) => {
	// const generalErrors = methods.errors['form'] ? [methods.errors['form']] : []
	// const generalErrors = []
	// console.debug('errors', methods.errors)

	const generalErrors = useMemo(() => {
		return form.errors.filter((err) => !err.fieldName)
	}, [form.errors])

	return (
		<FormProvider {...form}>
			<form
				id={id}
				autoComplete={autoComplete}
				onSubmit={(e) => {
					e.preventDefault()
					submit()
					// methods.handleSubmit(submit)
				}}
				className={clsx(className, {
					'has:error': form.errorCount,
					'is:dirty': form.dirtyCount,
					'is:loading': form.isLoading,
					'is:disabled': form.isDisabled,
					// 'is:loading': true,
				})}
			>
				<FormErrorList items={generalErrors} />
				{children}
			</form>

		</FormProvider>
	)
}
