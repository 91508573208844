import React, { useEffect, useCallback, forwardRef, FunctionComponent } from 'react'
import { isArray } from 'lodash'
import clsx from 'clsx'

import { useFormField, FormField } from '#hh/client/hooks'

import { FormErrorList } from '#hh/client/components'

import { InputProps } from '#hh/client/types'

// import {
// 	InputProps
// }

// export interface FormFieldProps {
// 	name: string
// 	required?: boolean
// 	onChange: Function
// }

interface FieldLabelProps extends React.HTMLAttributes<HTMLLabelElement> {
	// hasError?: boolean
	// isDirty?: boolean
	field?: FormField
	for?: string
}

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(({
	// for: _for,
	// className,
	// hasError,
	field,
	children,
	...props
}: any, ref) => {
	return (
		<label
			ref={ref}
			className="field__label"
			{...props}
			// className={clsx(
			// 	className,
			// 	'mb-2 text-sm font-semibold text-gray-600 pt-4',
			// 	{
			// 		'text-red-600': hasError,
			// 	},
			// )}
			// htmlFor={_for}
		>
			{children}
		</label>
	)
})

export interface FieldError {
	type: string
	message?: string
	field?: string
	row?: number
}

interface FieldErrorsProps extends React.HTMLAttributes<HTMLUListElement> {
	// errors: FieldError[]
	// className: string
	field: FormField
}

export const FieldErrors: React.FC<FieldErrorsProps> = ({
	field,
	className,
}) => {

	// const errors = useMemo(() => {
	// 	field.errors ?? []
	// })


	const errors = (field.errors ?? []).filter(e => !!e.message)

	if (!field.errors || !errors.length) {
		return null
	}

	return (
		<ul className={clsx(className, 'field__errors')}>
			{errors.map((error, index) => {

				return (
					<li key={index}>
						{error.message}
					</li>
				)

			})}
		</ul>
	)

}


// export const FieldErrorMessage: React.FC<any> = ({

// }) => {

// }

// interface FieldProps<TValue> extends InputProps<TValue> {
// 	name: string
// 	label: string
// 	naked: boolean
// 	className: string
// 	initialValue: any
// 	children(props: FieldInputProps<TValue>): JSX.Element
// }

// interface FieldInputProps<TValue> extends InputProps<TValue> {
// 	name: string
// 	label: string
// 	isDirty: boolean
// 	hasError: boolean
// }

// interface FieldInputProps {}
type FieldInputProps = any

// export interface CommonFieldProps<TValue = any, TElement = HTMLElement> extends React.HTMLAttributes<TElement>{
export interface CommonFieldProps<TValue = any, TElement = HTMLElement> {
	name: string
	label: string
	description?: string
	required?: boolean
	className?: string
	value?: TValue
	defaultValue?: TValue
	onChange?: (value: TValue, evt?: React.ChangeEvent<TElement>) => void
}
interface FieldProps extends CommonFieldProps {
	naked?: boolean
	children: (props: FieldInputProps) => React.ReactElement<any, any>
}

export const Field: React.FC<FieldProps> = ({
	name = '_',
	label,
	naked,
	children,
	className,
	onChange,
	value: controlledValue,
	defaultValue,
	...otherProps
}) => {
	const isControlled = controlledValue !== undefined
	const field = useFormField(name, {
		defaultValue,
	})

	const value = isControlled ? controlledValue : field.value
	const hasError = !!field.errors

	// useEffect(() => {
	// 	register(name)
	// 	setValue(name, value, {
	// 		shouldDirty: false,
	// 	})
	// 	return () => unregister(name)
	// }, [register])

	const handleChange = useCallback(
		(evt, newValue) => {
			field.setValue(newValue, {
				shouldDirty: true,
				shouldValidate: true,
			})

			if (onChange) {
				onChange(evt, newValue)
			}
		},
		[name, onChange, field.setValue],
	)

	const inputProps: FieldInputProps = {
		...otherProps,
		name,
		value: value,
		onChange: handleChange,
		hasError,
		isDirty: field.dirty,
		// className: {
		// 	'border-red-600': hasError,
		// },
	}

	if (naked) {
		return children(inputProps)
	}

	return (
		<div
			className={clsx('field', className)}
		>
			{Boolean(label) && (
				<FieldLabel field={field}>
					{label}
				</FieldLabel>
			)}
			{children(inputProps)}
			<FormErrorList items={field.errors} />
		</div>
	)
}
