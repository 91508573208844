import React, { forwardRef, ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'
import { PressEvent } from '@react-types/shared'
import { Spinner } from '#hh/client/components'
import { useButton } from 'react-aria'
import { useObjectRef } from '@react-aria/utils'
interface ButtonProps {
	className?: string
	icon?: string
	title?: string
	// active?: boolean
	loading?: boolean
	disabled?: boolean
	active?: boolean
	naked?: boolean
	tabIndex?: number
	excludeFromTabOrder?: boolean
	children?: React.ReactNode
	type?: 'submit' | 'reset' | 'button'
	style?: React.CSSProperties
	onPress?: (evt: PressEvent) => void
	onClick?: any
	onFocus?: any
	onPressStart?: (evt: PressEvent) => void
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			active,
			loading,
			disabled,
			naked,
			className: extraClassName,
			style,
			loading: isLoading,
			children,
			icon,
			type = 'button',
			tabIndex,
			excludeFromTabOrder,
			onPress,
			onClick,
			onPressStart,
			onFocus,
			title,
			...otherProps
		},
		forwardedRef,
	) => {
		const ref = useObjectRef(forwardedRef)
		const button = useButton(
			{
				type,
				isDisabled: disabled,
				excludeFromTabOrder,
				onPress,
				onPressStart,
				onFocus,
				...otherProps,
			},
			ref,
		)

		const className = clsx(extraClassName, {
			'button:naked': naked,
			'is:active': active,
			'is:loading': isLoading,
			'is:pressed': button.isPressed,
			// 'is:focus': button.pro,
			'is:disabled': button.buttonProps.disabled,
			'has:iconOnly': Boolean(icon) && !children,
			[`icon:${icon}`]: !isLoading && Boolean(icon),
		})

		return React.createElement(
			'button',
			{
				className,
				ref,
				// ...otherProps,
				title,
				style,
				...button.buttonProps,
				tabIndex,
			},
			<>
				{isLoading && <Spinner size="small" />}
				{children}
			</>,
		)
	},
)
