import React, { forwardRef, useRef, useCallback } from 'react'
import { useTextField } from 'react-aria'
import { useFormField } from '#hh/client/hooks'
import {
	Field,
	CommonFieldProps,
	TextInput,
	FieldLabel,
	FieldErrors,
} from '#hh/client/components'
import clsx from 'clsx'

interface TextFieldProps extends CommonFieldProps<string, HTMLInputElement> {
	type?: string
	autoComplete?: string
	placeholder?: string
	// name?: string
	children?: React.ReactNode
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
	(
		{ name, label, type = 'text', description, onChange, children, defaultValue,...otherProps },
		ref,
	) => {
		const field = useFormField(name, {
			defaultValue,
		})
		const inputRef = useRef()
		const {
			labelProps,
			inputProps,
			descriptionProps,
			errorMessageProps,
		} = useTextField(
			{ label, type, value: field.value ?? defaultValue, defaultValue, ...otherProps },
			inputRef,
		)

		const handleChange = useCallback(
			(newValue: string, evt: React.ChangeEvent<HTMLInputElement>) => {
				field.setValue(newValue, {
					shouldDirty: true,
					shouldValidate: true,
				})
				if (onChange) {
					onChange(newValue, evt)
				}
			},
			[name, onChange, field.setValue],
		)

		const hasError = !!field.errors
		const isDirty = field.dirty

		return (
			<div
				ref={ref}
				className={clsx('field field--text', {
					'has:error': hasError,
					'is:dirty': isDirty,
				})}
			>
				<FieldLabel {...labelProps} field={field}>
					{label}
				</FieldLabel>
				<TextInput children={children} {...inputProps} onChange={handleChange} ref={inputRef} className="field__input" />
				{Boolean(description) && <div {...descriptionProps}>{description}</div>}
				<FieldErrors {...errorMessageProps} field={field} />
			</div>
		)
	},
)

TextField.displayName = 'TextField'
