import React, { createContext, ReactNode } from 'react'
import {
	gql,
	useQuery,
	ApolloClient,
	NormalizedCacheObject,
} from '@apollo/client'
import { UserData } from '@neptun/commons'
import { Response } from 'express'
import { useRouter } from 'next/router'
// import { messages } from '#hh/shared/intl'

export interface PageAuthConfig {
	public?: boolean
	// capability?: Capability
}

interface ViewerContextData {
	user: UserData | null
}

const ViewerQuery = gql`
	query {
		viewer {
			id
			username
			email
			firstName
			lastName
			organization
			language
			role {
				name
			}
		}
	}
`

export const ViewerContext = createContext<ViewerContextData>({ user: null })

export const UserProvider = ({ children, user }) => {
	return (
		<ViewerContext.Provider value={{ user }}>{children}</ViewerContext.Provider>
	)
}

export const withAuth =
	(config: PageAuthConfig = {}) =>
	(PageComponent: any): ReactNode => {
		// const checkCapabilities = (viewer, capability) => {
		// 	// TODO check for user capability
		// 	if (!viewer.capabilities.includes(capability)) {
		// 		const error = {
		// 			title: 'Access denied',
		// 			statusCode: 401,
		// 		}
		// 		if (res) {
		// 			res.statusCode = 401
		// 		}
		// 		return {
		// 			error,
		// 			user: viewer,
		// 		}
		// 	}
		// }

		const WithAuth = (pageProps) => {
			const router = useRouter()

			// console.debug('with auth', Object.keys(pageProps))
			const { loading, error, data } = useQuery(ViewerQuery, {
				skip: config.public,
			})
			// NOTE error can contain 401 unauthorized

			// uncomment to disable login redirect
			// return <PageComponent {...props} />
			// if (error) {
			// 	console.log('error', error)
			// }

			// const requiresAuth = !config.public
			// const requiresAuth = true
			const user = data?.viewer as UserData

			if (!config.public && !loading && !user) {
				let redirectTarget = '/login'
				router.replace(redirectTarget)
			}

			const canRender = !loading && (config.public || Boolean(user))

			// if (requiresAuth && !viewer) {
			// 	res.writeHead(302, { location: '/login' })
			// 	res.end()
			// 	return
			// }
			// if (loading) {
			// 	return null
			// }
			// console.debug('canRender', canRender)

			return (
				<UserProvider user={user}>
					{/* { loading && (
					<div>loading animation?</div>
				)} */}
					{canRender && <PageComponent {...pageProps} user={user} />}
				</UserProvider>
			)
		}

		WithAuth.getInitialProps = async (ctx) => {
			let user: UserData | null = null

			if (!config.public) {
				const apolloClient =
					ctx.apolloClient as ApolloClient<NormalizedCacheObject>
				const res = ctx.res as Response

				try {
					const { data } = await apolloClient.query({
						query: ViewerQuery,
					})
					user = data.viewer as UserData
				} catch (err) {
					// console.error(err)
					const isAccessDenied =
						err.message === 'Access denied. Authentication required'
					if (!isAccessDenied) {
						console.error(err)
					}
					return res.redirect(302, '/login')
				}

				if (!user) {
					return res.redirect(302, '/login')
				}

				// if (capability && hasCapability(viewer, capability)) {
				// }
			}

			return {
				...(PageComponent.getInitialProps &&
					(await PageComponent.getInitialProps({
						...ctx,
						// messages: ctx.messages,
						// messages: messages[ctx.locale] ?? messages.en,
						user,
					}))),
				user,
			}
		}

		WithAuth.getLayout = PageComponent.getLayout

		return WithAuth as any
	}
