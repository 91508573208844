import React, { ReactNode, createContext } from 'react'
import { NextPage } from 'next'
import { defaultLocale, messages } from '#hh/shared/intl'

interface IntlContextType {
	locale: string
	messages: typeof messages['en']
}

export const IntlContext = createContext<IntlContextType>({
	locale: defaultLocale,
	messages: messages[defaultLocale] ?? {} as any,
})

export const IntlProvider = ({ children, messages, locale }) => {
	return (
		<IntlContext.Provider value={{ messages, locale }}>
			{children}
		</IntlContext.Provider>
	)
}

export const withIntl =
	() =>
	(PageComponent: any): ReactNode => {
		const WithIntl = (pageProps) => {
			return (
				<IntlProvider messages={pageProps.messages} locale={pageProps.locale}>
					<PageComponent {...pageProps} />
				</IntlProvider>
			)
		}

		WithIntl.getInitialProps = async (ctx) => {
			const locale = ctx.locale ?? defaultLocale
			const newContext = {
				...ctx,
				messages: messages[locale],
			}
			return {
				...(PageComponent.getInitialProps &&
					(await PageComponent.getInitialProps(newContext))),
				messages: messages[locale],
				locale,
			}
		}

		WithIntl.getLayout = PageComponent.getLayout
		return WithIntl as any
	}
