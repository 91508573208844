import React, { forwardRef } from 'react'
import NextLink from 'next/link'

export const Link = forwardRef<any, any>(
	({ children, className, onClick, href = '#', ...props }, ref) => {
		if (onClick) {
			return (
				<a
					ref={ref}
					href="#"
					className={className}
					onClick={(e) => {
						e.preventDefault()
						onClick(e)
					}}
				>
					{children}
				</a>
			)
		}

		return (
			<NextLink
				href={href}
				ref={ref} className={className}
				// href={'#'}
				{...props}
				// onTouchStart={() => {
				// 	alert('link touchstart')
				// }}
				// {...props}
			>
				{children}
				{/* <a ref={ref} className={className}>
					{children}
				</a> */}
			</NextLink>
		)
	},
)
